<template>
  <div class="showcase">
    <div class="header-box">
      <div>
        <h2 class="mb-4 mb-md-11">{{ $t("support.title") }}</h2>
        <v-text-field
          v-model="criteria"
          style="width: 70%"
          solo
          flat
          :placeholder="$t('search')"
          rounded
          hide-details
          prepend-inner-icon="mdi-magnify"
          @click:prepend-inner="setSearchParams({ criteria })"
          @keypress.enter="setSearchParams({ criteria })"
        ></v-text-field>
      </div>
    </div>
    <v-container>
      <v-row v-if="isNotSubRoute" class="my-7 mt-md-12">
        <v-col cols="12" md="4" @click="goTo('getting-started')">
          <v-card class="card h-360 my-10 mx-5 py-10">
            <v-img
              src="../../assets/img/start.svg"
              height="150"
              contain
              class="card-img"
            ></v-img>

            <v-card-title class="card-title justify-center">
              {{ $t("support.items.getting-started.title") }}
            </v-card-title>

            <v-card-text class="card-text">
              {{ $t("support.items.getting-started.summary") }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" @click="goTo('features')">
          <v-card class="card h-360 my-10 mx-5 py-10">
            <v-img
              src="../../assets/img/features.svg"
              height="150"
              contain
              class="card-img"
            ></v-img>

            <v-card-title class="card-title justify-center">
              {{ $t("support.items.features.title") }}
            </v-card-title>

            <v-card-text class="card-text">
              {{ $t("support.items.features.summary") }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" @click="goTo('personal-data')">
          <v-card class="card h-360 my-10 mx-5 py-10">
            <v-img
              src="../../assets/img/personal data.svg"
              height="150"
              contain
              class="card-img"
            ></v-img>

            <v-card-title class="card-title justify-center">
              {{ $t("support.items.personal-data.title") }}
            </v-card-title>

            <v-card-text class="card-text">
              {{ $t("support.items.personal-data.summary") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-container>
    <v-btn  v-if="!isNotSubRoute" @click="goUp" color="#fff" class="go-up-btn" elevation="3" fab>
      <v-icon color="#7566fd" size="40">mdi-chevron-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      criteria: null,
    };
  },
  computed: {
    isNotSubRoute() {
      return this.$route.name === "support";
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    goTo(componentName) {
      this.$router.push({ name: componentName });
    },
    goUp() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
